import { charsets, PasswordPolicy } from 'password-sheriff';
import { TFunction, i18n } from 'i18next';

const policy = {
    length: {
        minLength: 8,
    },
    containsAtLeast: {
        atLeast: 3,
        expressions: [
            charsets.upperCase,
            charsets.lowerCase,
            charsets.numbers,
            charsets.specialCharacters,
        ],
    },
};

export type Translatable = {
    code: string;
    format?: any[];
    items?: Translatable[];
    message: string;
    verified: boolean;
}

export type PasswordValidation = {
    verified: boolean;
    rules: Translatable[];
}

export const validatePassword = (password: string): PasswordValidation =>
    new PasswordPolicy(policy).missing(password);


const namespace = 'messages.passwordPolicy';

const interpolateTranslatable = (translatable: Translatable, t: TFunction, i18n: i18n, verifiedOnly = false): Translatable => {
    const translationKey = `${namespace}.${translatable.code}`;
    let formatString = i18n.exists(translationKey) ? t(translationKey) : translatable.message;
    if (translatable.format && translatable.format.length > 0) {
        let i = 0;
        formatString = formatString.replace(/%[sd]/g, () => translatable.format[i++] || '');
    }
    const result = { ...translatable, message: formatString };
    if (translatable.items) {
        result.items = translatable.items
            .filter(item => !verifiedOnly || !item.verified)
            .map(item => interpolateTranslatable(item, t, i18n));
    }
    return result;
};

/**
 * @param rules
 * @param t
 * @param i18n
 * @param verifiedOnly Will only contain rules that are not violated
 */
export const translatePasswordPolicy = (rules: Translatable[], t: TFunction, i18n: i18n, verifiedOnly = false): Translatable[] =>
    rules
        .filter(rule => !verifiedOnly || !rule.verified)
        .map(rule => interpolateTranslatable(rule, t, i18n, verifiedOnly));
