import React, { ComponentType, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
    },
    center: {
        textAlign: 'center',
    },
}));


export type ContentProps<P = React.HTMLProps<HTMLDivElement>> = P & {
    component?: ComponentType<P>;
    children: ReactNode;
    center?: boolean;
    className?: string;
}

export const Content: React.FunctionComponent<ContentProps> = props => {
    const classes = useStyles({});
    const {
        component: Component = 'div',
        className,
        center,
        children,
        ...rest
    } = props;
    return (
        <Component className={clsx(className, classes.content, center && classes.center)} {...rest}>
            {children}
        </Component>
    );
};
