import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import type { LocationDescriptor } from 'history';
import { Login, LoginRoutePath } from './Login';
import { RequestPasswordReset, RequestPasswordResetRoutePath } from './RequestPasswordReset';
import { Signup, SignupRoutePath } from './Signup';
import { JoinRoutePath, Join } from './Join';
import { featureEnabled, Feature, getDisabledFeatures, featureParameter } from '../../util/feature';


export const LoginWindow = () => {
    let defaultRedirect: LocationDescriptor = LoginRoutePath;
    if (getDisabledFeatures() > 0) {
        defaultRedirect = { pathname: defaultRedirect, search: `${featureParameter}=${getDisabledFeatures()}` };
    }
    return (
        <Switch>
            <Route path={LoginRoutePath} exact>
                <Login/>
            </Route>
            {featureEnabled(Feature.PasswordReset) && (
                <Route path={RequestPasswordResetRoutePath} exact>
                    <RequestPasswordReset/>
                </Route>
            )}
            {featureEnabled(Feature.Signup) && (
                <Route path={SignupRoutePath} exact>
                    <Signup/>
                </Route>
            )}
            <Route path={JoinRoutePath} exact>
                <Join/>
            </Route>
            <Redirect to={defaultRedirect}/>
        </Switch>
    );
};
