import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { visuallyhidden } from '@axes4/react-common/src/util/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { waitFor } from '../../util/waitFor';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
    icon?: React.ComponentType<SvgIconProps>;
    id: string;
    label: string;
}

interface InputStyleProps {
    active: boolean;
    hasError: boolean;
    hasIcon: boolean;
}

const borderColor = '#DDD';
const borderRadius = 4;

const getBorderColor = (theme: Theme, props: InputStyleProps) => {
    if (props.active) {
        return theme.palette.secondary.main;
    }
    if (props.hasError) {
        return theme.palette.error.dark;
    }
    return borderColor;
};

const useStyles = makeStyles<Theme, InputStyleProps>(theme => ({
    wrapper: {
        position: 'relative',
        width: '100%',
        marginTop: theme.spacing(2),
    },
    input: props => ({
        borderRadius: borderRadius,
        border: `1px solid ${getBorderColor(theme, props)}`,
        padding: `10px 16px 10px ${props.hasIcon ? 48 : 16}px`,
        boxSizing: 'border-box',
        width: '100%',
        fontSize: theme.typography.body1.fontSize,
        transition: 'border 0.3s ease-in-out',
        outline: 'none',
    }),
    inputIcon: {
        width: 16,
        height: 16,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-70%)',
        left: 6,
        fontSize: 18,
    },
    iconWrapper: props => ({
        background: '#EEE',
        border: `1px solid ${getBorderColor(theme, props)}`,
        position: 'absolute',
        width: 32,
        top: 0,
        bottom: 0,
        borderRadius: `${borderRadius}px 0 0 ${borderRadius}px`,
        transition: 'border 0.3s ease-in-out',
        zIndex: 1,
    }),
    inputLabel: {
        position: 'absolute',
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        color: theme.typography.caption.color,
        opacity: 0.7,
        padding: '12px 16px',
        pointerEvents: 'none',
    },
    inputLabelWithIcon: {
        paddingLeft: 48,
    },
    inputLabelWithValue: visuallyhidden,
}));

/**
 * Checks a few times and returns, whether the referenced element has an
 * autofill value displayed in chromium browsers.
 * This should be used to prevent rendering the label when the autofill value is
 * displayed.
 * @link https://github.com/mui/material-ui/issues/36448#issuecomment-1556738360
 * @param ref
 */
const useAutofillValue = (ref: MutableRefObject<HTMLInputElement>) => {
    const [ hasValue, setHasValue ] = useState(false);

    useLayoutEffect(() => {
        waitFor(() => {
            return !!ref.current && ref.current.matches(':-webkit-autofill') ? true : null;
        }, 500, 4)
            .then(() => {
                setHasValue(true);
            });
    }, []);

    return hasValue;
};

export const Input: React.FunctionComponent<InputProps> = props => {
    const [ active, setActive ] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const hasAutofillValue = useAutofillValue(inputRef);

    const {
        id,
        label,
        icon: IconComponent,
        className,
        ...inputProps
    } = props;

    const hasIcon = !!IconComponent;

    const handleFocus = () => setActive(true);
    const handleBlur = () => setActive(false);

    const classNames = useStyles({
        active,
        hasIcon,
        hasError: props['aria-invalid'] === true,
    });

    return (
        <div className={clsx(className, classNames.wrapper)}>
            {hasIcon && (
                <div className={classNames.iconWrapper}>
                    <div className={classNames.inputIcon}>
                        <IconComponent fontSize="inherit" />
                    </div>
                </div>
            )}
            <label
                className={clsx(
                    classNames.inputLabel,
                    hasIcon && classNames.inputLabelWithIcon,
                    (props.value || hasAutofillValue) && classNames.inputLabelWithValue
                )}
                htmlFor={id}
            >
                {label}
            </label>
            <input
                ref={inputRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                id={id}
                className={classNames.input}
                type="text"
                {...inputProps}
            />
        </div>
    );
};
