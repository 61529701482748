import React, { useMemo } from 'react';
import { Translatable, translatePasswordPolicy, validatePassword } from '../../util/password-policy';
import { useTranslation } from 'react-i18next';
import OkIcon from '@axes4/react-icons/jsx/Ok';
import ErrorIcon from '@axes4/react-icons/jsx/Error';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { VisuallyHidden } from '@axes4/react-common/src/components/Utility/VisuallyHidden';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBlock: '1rem',
    },
    list: {
        margin: 0,
        listStyleType: 'none',
        paddingInlineStart: '2rem',
    },
    item: {
        marginBlockStart: '0.25rem',
        position: 'relative',
    },
    icon: {
        height: '1rem',
        width: '1rem',
        position: 'absolute',
        left: '-1.5rem',
        top: 0,
    },
    iconFulfilled: {
        color: theme.palette.primary.main,
    },
    iconUnfulfilled: {
        color: theme.palette.error.dark,
    },
}));

interface PasswordStrengthProps extends React.HTMLProps<HTMLDivElement> {
    password: string;
    showResolved?: boolean;
    onValidate: (valid: boolean) => void;
}

export const PasswordStrength: React.FunctionComponent<PasswordStrengthProps> = props => {
    const { t, i18n } = useTranslation();
    const { password, showResolved, onValidate, className, ...divProps } = props;
    const classes = useStyles({});

    const analysis = useMemo(() => {
        const analysis = validatePassword(password);

        if (!analysis.rules) {
            return null;
        }

        return {
            ...analysis,
            rules: translatePasswordPolicy(analysis.rules, t, i18n),
        };
    }, [ password ]);

    onValidate(analysis.verified);

    if (!analysis || (analysis.verified && !showResolved)) {
        return null;
    }

    const mergedClassName = clsx(className, classes.container);

    return (
        <div {...divProps} className={mergedClassName}>
            <RuleList items={analysis.rules} />
        </div>
    );
};

interface RuleItemProps {
    message: string;
    verified?: boolean;
    items?: Translatable[];
}

const RuleItem: React.FunctionComponent<RuleItemProps> = props => {
    const { t } = useTranslation();
    const classes = useStyles({});

    const iconProps = useMemo(() => ({
        className: clsx(
            classes.icon,
            props.verified ? classes.iconFulfilled : classes.iconUnfulfilled
        ),
        'aria-hidden': true,
    }), [ classes.icon, props.verified ]);

    const translationKey = props.verified
    ? 'messages.passwordPolicy.fulfilled'
    : 'messages.passwordPolicy.unfulfilled';
    const Icon = props.verified ? OkIcon : ErrorIcon;

    return (
        <li className={classes.item}>
            <Icon {...iconProps} />
            <VisuallyHidden>{t(translationKey) + ' '}</VisuallyHidden>
            {props.message}
            <RuleList items={props.items} />
        </li>
    );
};

interface RuleListProps {
    items: Translatable[];
}

const RuleList: React.FunctionComponent<RuleListProps> = props => {
    const classes = useStyles({});

    if (!props.items || props.items.length < 1) {
        return null;
    }

    return (
        <ul className={classes.list}>
            {props.items.map((item, i) => (
                <RuleItem {...item} key={i} />
            ))}
        </ul>
    );
};
