let config = null;

export const getConfig = (): typeof window.__config => {
    if (config === null) {
        config = window.__config;
    }
    return config;
};

export type AuthConfig = {
    customDomain: string,
    domain: string,
    clientId: string,
    audience: string,
    connection: string,
    realm: string,
};

export type AppConfig = {
    apiUrls: {
        licensing: string,
        id: string,
    },
};

declare global {
    interface Window {
        __config: {
            auth?: AuthConfig,
            app?: AppConfig
        },
    }
}
