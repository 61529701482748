import React, { useEffect, useState } from 'react';
import { Content } from '../common/Content';
import { Input } from '../common/Input';
import { webAuth } from '../../auth0';
import MailIcon from '@axes4/react-icons/jsx/Mail';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../common/LinkButton';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitButton } from './SubmitButton';
import { LoginRoutePath } from './Login';
import { SignupRoutePath } from './Signup';
import { usePageTitle } from '../../hooks/layout';
import { Layout } from './Layout';
import { getConfig } from '../../config';
import { Feature, featureEnabled } from '../../util/feature';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import { FlashMessageContainer } from '../common/FlashMessageContainer';

const auth0Config = getConfig().auth;

export const RequestPasswordResetRoutePath = '/password-reset';

const useStyles = makeStyles({
    center: {
        textAlign: 'center',
    },
});

export const RequestPasswordReset: React.FunctionComponent = () => {
    const { addMessage, clear } = useFlashMessages();
    const [ email, setEmail ] = useState('');
    const [ busy, setBusy ] = useState(false);
    const { t, i18n } = useTranslation();
    usePageTitle(t('requestPasswordReset.action'));

    useEffect(() => {
        clear();
    }, []);

    const handlePasswordResetRequest = (e) => {
        e.preventDefault();
        setBusy(true);
        webAuth.changePassword({
            email: email,
            connection: auth0Config.connection,
        }, (err, resp) => {
            setBusy(false);
            if (err) {
                console.error(err);
                const messageKey = `requestPasswordReset.messages.${err.code}`;
                const message = i18n.exists(messageKey) ? t(messageKey) : (err.description || t('messages.error'));
                addMessage({ message, variant: MessageLevel.Error });
            } else {
                const messageKey = 'requestPasswordReset.messages.success';
                const message = i18n.exists(messageKey) ? t(messageKey) : resp;
                addMessage({ message, variant: MessageLevel.Success });
            }
        });
    };
    const classes = useStyles({});

    return (
        <Layout
            title={t('requestPasswordReset.header.title')}
            subtitle={t('requestPasswordReset.header.subtitle')}
        >
            <form onSubmit={handlePasswordResetRequest}>
                <Content>
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                        label={t('requestPasswordReset.email.label')}
                        autoComplete="email"
                        icon={MailIcon}
                        required
                    />
                    <FlashMessageContainer/>
                </Content>
                <Content className={classes.center}>
                    {featureEnabled(Feature.Login) && (
                        <LinkButton
                            to={LoginRoutePath}
                        >
                            {t('links.login') as string}
                        </LinkButton>
                    )}
                    {featureEnabled(Feature.Signup) && (
                        <LinkButton
                            to={SignupRoutePath}
                        >
                            {t('links.signup') as string}
                        </LinkButton>
                    )}
                </Content>
                <SubmitButton busy={busy}>{t('requestPasswordReset.action') as string}</SubmitButton>
            </form>
        </Layout>
    );
};
