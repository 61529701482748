module.exports = {
    lock: {
        passwordReset: {
            configurationError: 'An error ocurred. It seems there is a misconfiguration in the form.',
            headerText: 'Enter a password for<br />{email}',
            networkError: 'The server cannot be reached, there is a problem with the network.',
            passwordConfirmationMatchError: 'Please ensure the password and the confirmation are the same.',
            passwordConfirmationPlaceholder: 'Confirm your new password.',
            passwordHistoryError: 'Password has been used previously.',
            passwordPlaceholder: 'New password',
            passwordStrength: {
                containsAtLeast: 'Contains at least %d of the following %d types of characters:',
                identicalChars: 'No more than %d identical characters in one row (e.g., "%s" not allowed)',
                lengthAtLeast: 'At least %d characters in length',
                lowerCase: 'Lower case letters (a-z)',
                nonEmpty: 'Non-empty password required',
                numbers: 'Numbers (i.e. 0-9)',
                shouldContain: 'Should contain:',
                specialCharacters: 'Special characters (e.g. !@#$%^&*)',
                upperCase: 'Upper case letters (A-Z)',
            },
            serverError: 'There was an error processing the password reset.',
            submit: 'Change Password',
            successMessage: 'Your password has been changed successfully.',
            timeoutError: 'The server cannot be reached, please try again.',
            title: 'Change password',
            weakPasswordError: 'Password is too weak.',
        },
    },
    translation: {
        general: {
            loading: 'Loading ...',
        },
        join: {
            action: 'Sign up',
            firstName: {
                label: 'First name (optional)',
            },
            header: {
                subtitle: 'Create an axes4 account to accept your invitation',
                title: 'Sign up',
            },
            lastName: {
                label: 'Last name (optional)',
            },
            messages: {
                3040: 'This is not a valid invitation link.',
                3041: 'This is not a valid invitation link.',
                3042: 'This invitation does not exist.',
                4001: 'Password is too weak.',
                error: 'axes4 account could not be created.',
                noCode: 'This is not a valid invitation link.',
                serverConnection: 'Could not connect to the server.',
            },
            password: {
                label: 'Password',
            },
            text: 'Create an axes4 account for your email address <strong>{{email}}</strong> to join the license account <strong>{{accountName}}</strong>.',
        },
        links: {
            login: 'To axes4 Login',
            passwordReset: 'Forgot your password?',
            signup: 'Sign up',
        },
        loggedout: {
            header: {
                subtitle: 'Your session has expired.',
                title: 'Logged out',
            },
            toProfile: 'Login again',
        },
        login: {
            action: 'Login',
            email: {
                label: 'Email',
            },
            header: {
                subtitle: 'Login for axes4 applications',
                title: 'axes4 Login',
            },
            messages: {
                access_denied: 'Wrong email or password',
                request_error: 'Login could not be submitted',
            },
            password: {
                label: 'Password',
            },
            username: {
                label: 'Username',
            },
        },
        messages: {
            error: 'An error occurred',
            passwordPolicy: {
                containsAtLeast: 'Contains at least %d of the following %d types of characters:',
                fulfilled: 'fulfilled:',
                identicalChars: 'No more than %d identical characters in a row (e.g., "%s" not allowed)',
                lengthAtLeast: 'At least %d characters in length',
                lowerCase: 'Lower case letters (a-z)',
                nonEmpty: 'Non-empty password required',
                numbers: 'Numbers (i.e. 0-9)',
                shouldContain: 'Should contain:',
                specialCharacters: 'Special characters (e.g. !@#$%^&*)',
                unfulfilled: 'unfulfilled:',
                upperCase: 'Upper case letters (A-Z)',
            },
        },
        popup: {
            subtitle: 'Login for axes4 applications',
            title: 'axes4 Login',
        },
        profile: {
            email: {
                label: 'Email address',
            },
            firstName: {
                label: 'First name',
            },
            introText: 'In the profile settings you have the option to update the information regarding your person and change cross-application preferences.',
            language: {
                label: 'Language',
            },
            lastName: {
                label: 'Family name',
            },
            logout: 'Logout',
            messages: {
                'account saved': 'Your changes have been saved.',
                'connection error': 'The information could not be saved. Try reloading the page.',
                'email changed': 'The email has been changed. In order to log in with the new email address you have to verify it first.',
                'not initialized': 'Application could not initialize. Try reloading the page.',
            },
            notVerified: {
                resend: 'Resend Verification',
                text: 'Your email address has not been verified yet. Until that you can\'t make changes to your profile.',
            },
            pageTitle: 'axes4 ID',
            submit: 'save',
            title: 'Account settings',
        },
        requestPasswordReset: {
            action: 'Reset password',
            email: {
                label: 'Email',
            },
            header: {
                subtitle: 'Enter your email address to receive a link to reset your password',
                title: 'Reset password',
            },
            messages: {
                'email or username are required': 'The email address is required!',
                success: 'We\'ve just sent you an email to reset your password.',
            },
        },
        signup: {
            action: 'Create axes4 account',
            email: {
                label: 'Email',
            },
            header: {
                subtitle: 'Sign up',
                title: 'axes4 ID',
            },
            messages: {
                invalid_password: 'Invalid password',
                request_error: 'There was a problem submitting your sign up data.',
                success: 'Your account has been created. Please verify your email and login.',
                user_exists: 'This email address is already in use.',
            },
            password: {
                label: 'Password',
            },
            username: {
                label: 'Username',
            },
        },
    },
};
