/**
 * Retries until the condition is met. Can be used to await e.g. DOM contents
 * being present.
 * @param condition Can return anything but null or false.
 *      The promise will resolve to that value.
 * @param timeout How long to wait between retries.
 * @param retries How often to retry checking the condition.
 */
export const waitFor = <T>(condition: () => NonNullable<T>|null, timeout: number, retries: number): Promise<T> =>
    new Promise((resolve, reject) => {
        let to = 0;
        let tries = 0;
        const check = () => {
            const result = condition();
            if (result !== null && result !== false) {
                resolve(result);
                window.clearTimeout(to);
                return;
            }
            tries++;
            if (tries < retries) {
                to = window.setTimeout(check, timeout);
            } else {
                reject('Condition not fulfilled');
            }
        };
        check();
    });
