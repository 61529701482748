import i18n, { ThirdPartyModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './lang/de.js';
import en from './lang/en.js';

export const languages = {
    de: 'Deutsch',
    en: 'English',
};

const changeHtmlLanguage: ThirdPartyModule = {
    type: '3rdParty',
    init: i18n => {
        i18n.on('languageChanged', l => document.documentElement.lang = l);
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(changeHtmlLanguage)
    .init({
        resources: {
            'en': en,
            'de': de,
        },
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
