import React from 'react';
import { Link, LinkProps, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

const QueryLink: React.FunctionComponent<RouteComponentProps & LinkProps> = props => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { to, location, staticContext, ...rest } = props;
    return (
        <Link
            to={typeof to === 'string' ? { pathname: to, search: location.search } : to}
            {...rest}
        />
    );
};

export default withRouter(QueryLink);
