import React from 'react';
import { useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import { Message } from './Message';

export const FlashMessageContainer: React.FunctionComponent = () => {
    const { messages } = useFlashMessages();

    return (
        <>
            {messages.map(message => (
                <Message
                    key={message.id}
                    variant={message.variant}
                >
                    {message.message}
                </Message>
            ))}
        </>
    );
};
