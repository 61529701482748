import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    submitButton: {
        background: theme.palette.secondary.main,
        border: 'none',
        width: '100%',
        color: theme.palette.secondary.contrastText,
        fontSize: theme.typography.body1.fontSize,
        padding: 16,
        borderRadius: '0 0 4px 4px',
        position: 'relative',
        cursor: 'pointer',
        '&:hover, &:focus': {
            background: theme.palette.secondary.dark,
        },
        '&[disabled]': {
            cursor: 'default',
            background: theme.palette.text.disabled,
        },
    },
    spinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

interface SubmitButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    busy?: boolean;
}

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = props => {
    const { className, children, busy = false, ...rest } = props;
    const [ showSpinner, setShowSpinner ] = useState(false);
    const timeout = useRef<number>();

    useEffect(() => {
        if (busy) {
            timeout.current = window.setTimeout(() => {
                setShowSpinner(true);
            }, 300);
        } else {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            setShowSpinner(false);
        }
    }, [ busy ]);

    const classes = useStyles({});

    return (
        <button
            type="submit"
            disabled={props.disabled || busy}
            className={clsx(className, classes.submitButton)}
            {...rest}
        >
            {children}
            {busy && showSpinner && (<div className={classes.spinner}><CircularProgress size={30}/></div>)}
        </button>
    );
};
