import React, { useState, useEffect, useRef } from 'react';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/lightBlue';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { MessageLevel } from '@axes4/react-common/src/components/FlashMessages';

const useStyles = makeStyles({
    message: {
        padding: 10,
        border: '1px solid',
        marginTop: 16,
        borderRadius: 4,
    },
    info: {
        backgroundColor: blue[50],
        borderColor: blue[600],
    },
    success: {
        backgroundColor: green[50],
        borderColor: green[900],
    },
    warning: {
        backgroundColor: orange[50],
        borderColor: orange[800],
    },
    error: {
        backgroundColor: red[50],
        borderColor: red[800],
    },
});

interface MessageProps {
    autohide?: number;
    children: React.ReactNode;
    variant: MessageLevel;
}

export const Message: React.FunctionComponent<MessageProps> = props => {
    const { children: message, variant, autohide = 0 } = props;
    const [ visible, setVisible ] = useState(true);
    const timeoutRef = useRef<number>();
    const classes = useStyles({});

    if (autohide > 0) {
        useEffect(() => {
            timeoutRef.current = window.setTimeout(() => setVisible(false), autohide);
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            };
        });
    }

    return visible && (
        <div className={clsx(classes.message, classes[variant])}>
            {message}
        </div>
    );
};
