import React, { useCallback, useEffect, useState } from 'react';
import { Content } from '../common/Content';
import { Input } from '../common/Input';
import { LinkButton } from '../common/LinkButton';
import { SubmitButton } from './SubmitButton';
import { useTranslation } from 'react-i18next';
import MailIcon from '@axes4/react-icons/jsx/Mail';
import LockIcon from '@axes4/react-icons/jsx/Lock';
import { getAuth0Conf, webAuth } from '../../auth0';
import { LoginRoutePath } from './Login';
import { makeStyles } from '@material-ui/core/styles';
import { usePageTitle } from '../../hooks/layout';
import { Layout } from './Layout';
import { Feature, featureEnabled } from '../../util/feature';
import { PasswordStrength } from '../common/PasswordStrength';
import { Auth0Error, CrossOriginLoginOptions } from 'auth0-js';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import { FlashMessageContainer } from '../common/FlashMessageContainer';

const useStyles = makeStyles(() => ({
    center: {
        textAlign: 'center',
    },
}));

export const SignupRoutePath = '/signup';

interface SignupProps {
    onSuccess?(): void,
    onError?(err: Auth0Error): void,
}

export const Signup: React.FunctionComponent<SignupProps> = props => {
    const { t, i18n } = useTranslation();
    usePageTitle(t('signup.action'));
    const classes = useStyles({});
    const { onSuccess, onError } = props;
    const { addMessage, clear } = useFlashMessages();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ hasPasswordInput, setHasPasswordInput ] = useState(false);
    const [ passwordValid, setPasswordValid ] = useState(false);
    const [ busy, setBusy ] = useState(false);
    const auth0Config = getAuth0Conf();
    const { connection } = auth0Config;

    useEffect(() => {
        clear();
    }, []);

    const handleSubmit = e => {
        setBusy(true);
        e.preventDefault();
        webAuth.signup({
            connection,
            email,
            password,
            userMetadata: {
                language: i18n.language,
            },
        }, (err) => {
            setBusy(false);
            if (err) {
                const messageKey = `signup.messages.${err.code}`;
                const message = i18n.exists(messageKey) ? t(messageKey) : (err.description || t('messages.error'));
                addMessage({ message, variant: MessageLevel.Error });
                onError?.(err);
            } else {
                const redirectUri = auth0Config.redirectUri;
                if (redirectUri) {
                    const loginOptions: CrossOriginLoginOptions = {
                        clientID: auth0Config.clientId,
                        domain: auth0Config.customDomain,
                        realm: auth0Config.realm,
                        audience: auth0Config.audience,
                        email,
                        password,
                        redirectUri: auth0Config.redirectUri || document.location.toString(),
                        responseType: auth0Config.responseType,
                        scope: 'openid',
                    };
                    if (auth0Config.nonce) {
                        loginOptions.nonce = auth0Config.nonce;
                    }
                    if (auth0Config.state) {
                        loginOptions.state = auth0Config.state;
                    }
                    // signin
                    webAuth.login(loginOptions, (err) => {
                        if (err) {
                            console.error(err);
                        }
                    });
                }
                onSuccess?.();
                clear();
                addMessage({ message: t('signup.messages.success'), variant: MessageLevel.Success });
            }
        });
    };

    const handlePasswordChange = useCallback(e => {
        const value = e.target.value;
        setPassword(value);
        setHasPasswordInput(v => v || !!value);
    }, []);

    return (
        <Layout
            title={t('signup.header.title')}
            subtitle={t('signup.header.subtitle')}
        >
            <form onSubmit={handleSubmit}>
                <Content>
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                        label={t('signup.email.label')}
                        autoComplete="email"
                        icon={MailIcon}
                        required
                    />
                    <Input
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        label={t('signup.password.label')}
                        autoComplete="new-password"
                        type="password"
                        icon={LockIcon}
                        required
                        aria-invalid={!!password && !passwordValid}
                    />
                    {hasPasswordInput && (
                        <PasswordStrength
                            password={password}
                            onValidate={setPasswordValid}
                            aria-atomic
                            aria-live="assertive"
                            showResolved
                        />
                    )}
                    <FlashMessageContainer/>
                </Content>
                <Content className={classes.center}>
                    {featureEnabled(Feature.Login) && (
                        <LinkButton
                            to={LoginRoutePath}
                        >
                            {t('links.login') as string}
                        </LinkButton>
                    )}
                </Content>
                <SubmitButton busy={busy}>{t('signup.action') as string}</SubmitButton>
            </form>
        </Layout>
    );
};
