import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import QueryLink from './QueryLink';
import { LinkProps } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    linkButton: {
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        padding: 0,
        margin: `0 auto ${theme.spacing(1)}px`,
        display: 'block',
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        '&:hover, &:focus, &:active': {
            color: theme.palette.secondary.dark,
        },
    },
    inlineLinkButton: {
        display: 'inline',
        margin: 0,
    },
}));

export interface LinkButtonProps extends Omit<LinkProps, 'color'> {
    children: React.ReactNode;
    inline?: boolean;
    className?: string;
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = props => {
    const { children, inline = false, className, ...rest } = props;
    const classes = useStyles({});
    return (
        <Typography
            component={QueryLink}
            className={clsx(className, classes.linkButton, inline && classes.inlineLinkButton)}
            {...rest}
        >
            {children}
        </Typography>
    );
};
