import '@babel/polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App/App';
import './i18n';
import { isOldIE } from '@axes4/react-common/src/util';

if (isOldIE()) {
    document.write('Please consider upgrading to a newer web browser');
    throw Error('Detected IE < 11, which is not supported');
}

const entry = Object.assign(document.createElement('DIV'), { id: 'app' });
document.body.appendChild(entry);
ReactDOM.render(<App/>, entry);
