import React, { useCallback } from 'react';
import { useState } from 'react';
import { Content } from '../../common/Content';
import { Input } from '../../common/Input';
import { SubmitButton } from '../SubmitButton';
import { useTranslation, Trans } from 'react-i18next';
import LockIcon from '@axes4/react-icons/jsx/Lock';
import UserIcon from '@axes4/react-icons/jsx/User';
import { Typography } from '@material-ui/core';
import { PasswordStrength } from '../../common/PasswordStrength';
import { FlashMessageContainer } from '../../common/FlashMessageContainer';

interface JoinFormProps {
    email?: string;
    accountName?: string;
    signup: (password: string, firstName: string, lastName: string) => Promise<unknown>;
    busy: boolean;
}

const Form: React.FunctionComponent<JoinFormProps> = props => {
    const { t } = useTranslation();
    const [ password, setPassword ] = useState('');
    const [ passwordValid, setPasswordValid ] = useState(false);
    const [ hasPasswordInput, setHasPasswordInput ] = useState(false);
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.signup(password, firstName, lastName);
    };

    const handlePasswordChange = useCallback(e => {
        const value = e.target.value;
        setPassword(value);
        setHasPasswordInput(v => v || !!value);
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Content>
                <Typography paragraph>
                    <Trans
                        i18nKey="join.text"
                        components={{
                            'strong': <strong/>,
                        }}
                        values={{
                            email: props.email,
                            accountName: props.accountName,
                        }}
                    />
                </Typography>
                <Input
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    label={t('join.password.label')}
                    autoComplete="new-password"
                    type="password"
                    icon={LockIcon}
                    required
                    aria-invalid={!!password && !passwordValid}
                />
                {hasPasswordInput && (
                    <PasswordStrength
                        password={password}
                        onValidate={setPasswordValid}
                        aria-atomic
                        aria-live="assertive"
                        showResolved
                    />
                )}
                <Input
                    id="firstName"
                    value={firstName}
                    onChange={e => setFirstName(e.currentTarget.value)}
                    label={t('join.firstName.label')}
                    autoComplete="given-name"
                    icon={UserIcon}
                />
                <Input
                    id="lastName"
                    value={lastName}
                    onChange={e => setLastName(e.currentTarget.value)}
                    label={t('join.lastName.label')}
                    autoComplete="family-name"
                    icon={UserIcon}
                />
                <FlashMessageContainer/>
            </Content>
            <SubmitButton busy={props.busy}>{t('join.action') + ''}</SubmitButton>
        </form>
    );
};

export default Form;
