import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from './Layout';
import { Content } from '../common/Content';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import QueryString from 'query-string';
import { getConfig } from '../../config';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import { Auth0UserProfile } from 'auth0-js';
import { FlashMessageContainer } from '../common/FlashMessageContainer';

const { apiUrls } = getConfig().app;
const Form = React.lazy(() => import('./Join/Form'));

export const JoinRoutePath = '/join';

export const resolveEndpoint = (endpoint: string): string => {
    const m = endpoint.match(/([\w-]+):\/(.*)$/);
    if (!m) {
        return endpoint;
    }
    const type = m[1];
    const path = m[2];
    return (apiUrls[type] || '') + '/' + path;
};

const apiRequest = (path: string, method = 'GET', payload = null): Promise<Response> => {
    return fetch(resolveEndpoint(path), {
        method,
        body: payload ? JSON.stringify(payload) : null,
        cache: 'no-cache',
    });
};

const Loader = () => (
    <Content center>
        <CircularProgress/>
    </Content>
);

type ErrorResponse = {
    error: { code: number; message: string; };
    data?: null;
}
type SuccessResponse<T> = {
    error?: null;
    data: T;
}
type ServerResponse<T> = ErrorResponse | SuccessResponse<T>;

type InvitationInformation = {
    invitation: string;
    accountName: string;
    email: string;
}

export const Join: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const { addMessage } = useFlashMessages();
    // const [ checkingInvitation, setCheckingInvitation ] = useState(true);
    // const [ invitationValid, setInvitationValid ] = useState(false);
    const [ checkingInvitation, setCheckingInvitation ] = useState(false);
    const [ invitationValid, setInvitationValid ] = useState(true);
    const [ invitationCode, setInvitationCode ] = useState('');
    const [ invitationService, setInvitationService ] = useState('');
    const [ accountInvitedTo, setAccountInvitedTo ] = useState(null);
    const [ emailInvited, setEmailInvited ] = useState('');
    const [ busy, setBusy ] = useState(false);

    const signup = useCallback((password: string, firstName: string, lastName: string) => {
        setBusy(true);
        const payload: Partial<Auth0UserProfile> & { password: string } = {
            password,
            user_metadata: {
                language: i18n.language,
            },
        };
        if (firstName) {
            payload.given_name = firstName;
        }
        if (lastName) {
            payload.family_name = lastName;
        }

        return apiRequest(`id:/invitation/${invitationService}/${invitationCode}/accept`, 'POST', payload)
            .then(async r => {
                setBusy(false);

                if (r.ok) {
                    const query = QueryString.parse(document.location.search);
                    if (typeof query.redirect_uri === 'string' && !!query.redirect_uri) {
                        document.location.href = query.redirect_uri;
                    } else {
                        document.location.href = '/';
                    }
                } else {
                    let errorMessage = t('join.messages.error');
                    try {
                        const responseBody: ServerResponse<any> = await r.json();
                        if (responseBody && responseBody.error && responseBody.error.code) {
                            const translationKey = `join.messages.${responseBody.error.code}`;
                            if (i18n.exists(translationKey)) {
                                errorMessage = t(translationKey);
                            }
                        }
                    } catch (e) {}

                    addMessage({
                        message: errorMessage,
                        variant: MessageLevel.Error,
                    });
                }
            });
    }, [ addMessage, t, i18n, invitationService, invitationCode ]);

    useEffect(() => {
        setCheckingInvitation(true);
        setInvitationValid(false);
        const query = QueryString.parse(document.location.search);
        if (typeof query.invitation === 'string' && !!query.invitation && typeof query.service === 'string' && !!query.service) {
            const invitationCode = query.invitation.replace(/[^a-fA-F0-9-]/g, '');
            const service = query.service.replace(/[^a-zA-Z-]/g, '');
            apiRequest(`id:/invitation/${service}/${invitationCode}`)
                .then(r => r.text())
                .then(text => {
                    try {
                        const response: ServerResponse<InvitationInformation> = JSON.parse(text);
                        if (!response.error && response.data) {
                            setInvitationCode(response.data.invitation);
                            setInvitationService(service);
                            setAccountInvitedTo(response.data.accountName);
                            setEmailInvited(response.data.email);
                            setInvitationValid(true);
                        } else if (response.error) {
                            if (response.error.code && i18n.exists(`join.messages.${response.error.code}`)) {
                                addMessage({
                                    message: t(`join.messages.${response.error.code}`),
                                    variant: MessageLevel.Error,
                                });
                            } else {
                                addMessage({
                                    message: t('join.messages.serverConnection'),
                                    variant: MessageLevel.Error,
                                });
                            }
                        }
                    } catch (parseError) {
                        addMessage({
                            message: t('join.messages.serverConnection'),
                            variant: MessageLevel.Error,
                        });
                    }
                })
                .then(() => {
                    setCheckingInvitation(false);
                });
        } else {
            setCheckingInvitation(false);
            addMessage({
                message: t('join.messages.noCode'),
                variant: MessageLevel.Error,
            });
        }
    }, [ document.location.search ]);

    return (
        <Layout
            title={t('join.header.title')}
            subtitle={t('join.header.subtitle')}
        >
            {checkingInvitation && (
                <Loader/>
            )}
            {!checkingInvitation && !invitationValid && (
                <Content>
                    <FlashMessageContainer/>
                </Content>
            )}
            {!checkingInvitation && invitationValid && (
                <React.Suspense fallback={<Loader/>}>
                    <Form
                        accountName={accountInvitedTo}
                        email={emailInvited}
                        busy={busy}
                        signup={signup}
                    />
                </React.Suspense>
            )}
        </Layout>
    );
};
