import { getAuthenticatedUserId, getAuthenticationToken } from '@axes4/react-common/src/auth/auth0-helper';
import { CardTitle, CardWithClearing } from '@axes4/react-common/src/components/Card';
import { FlashMessage, MessageLevel, useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import UserIcon from '@axes4/react-icons/jsx/User';
import {
    Box,
    Button,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../../i18n';
import { InitializationHelper } from './InitializationHelper';
import { SessionStore } from '@axes4/react-common/src/auth/session-store';
import { resolveEndpoint } from '../LoginWindow/Join';
import { getConfig } from '../../config';
import type { Auth0UserProfile } from 'auth0-js';

const auth0Credentials = getConfig().auth;

const awaitAuthStorageKey = 'await_auth';

const apiRequest = (path: string, method = 'GET', payload = null): Promise<Response> => {
    const token = getAuthenticationToken();

    if (!token) {
        throw new Error('Not Authorized');
    }

    return fetch(resolveEndpoint(path), {
        method,
        body: payload ? JSON.stringify(payload) : null,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        cache: 'no-cache',
    });
};

const getProfile = (): Promise<Auth0UserProfile> => apiRequest('id:/userinfo')
    .then(r => r.json());

type UpdateProfileData = {
    email: string,
    firstName: string;
    lastName: string;
    language: string;
}

const updateProfile = ({ email, firstName, lastName, language }: UpdateProfileData): Promise<Response> =>
    apiRequest('id:/update', 'POST', {
        given_name: firstName,
        family_name: lastName,
        email: email,
        user_metadata: {
            language,
        },
    });

export const ProfileProperties = () => {
    const { t, i18n } = useTranslation();
    const { addMessage: createFlashMessage } = useFlashMessages();
    const [ email, setEmail ] = useState('');
    const [ oldEmail, setOldEmail ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ language, setLanguage ] = useState(i18n.language);
    const [ submitting, setSubmitting ] = useState(false);
    const [ auth0User, setAuth0User ] = useState<Auth0UserProfile>(null);
    const hasUser = useRef(false);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const languageOptions = languages;

    const updateFormFromApiData = (userData: Auth0UserProfile): void => {
        setAuth0User(userData);
        setEmail(userData.email);
        setOldEmail(userData.email);
        setFirstName(userData.given_name || '');
        setLastName(userData.family_name || '');
        setLanguage(userData.user_metadata.language || i18n.language);
        i18n.changeLanguage(userData.user_metadata.language);
    };

    const resendVerificationMail = () =>
        fetch('https://login.auth0.com/api/v2/jobs/verification-email', {
            method: 'POST',
            body: JSON.stringify({
                user_id: auth0User.sub,
                client_id: auth0Credentials.clientId,
            }),
        });

    const handleSubmit = e => {
        e.preventDefault();
        setSubmitting(true);
        try {
            updateProfile({
                email,
                firstName,
                lastName,
                language,
            })
                .then(async r => {
                    setSubmitting(false);
                    const content = r.headers['Content-Length'] > 0 ? await r.json() : '';
                    if (r.ok) {
                        return content;
                    } else {
                        throw new Error(content);
                    }
                })
                .then(() => {
                    const metadata = auth0User.user_metadata || {};
                    metadata.language = language;
                    updateFormFromApiData({
                        ...auth0User,
                        email,
                        given_name: firstName,
                        family_name: lastName,
                        user_metadata: metadata,
                    });
                    createFlashMessage({
                        message: t('profile.messages.account saved'),
                        variant: MessageLevel.Success,
                    });
                    if (oldEmail !== email) {
                        createFlashMessage({
                            message: t('profile.messages.email changed'),
                            variant: MessageLevel.Info,
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    createFlashMessage({
                        message: t('profile.messages.not initialized'),
                        variant: MessageLevel.Error,
                    });
                });
        } catch (e) {
            setSubmitting(false);
            console.error(e);
            createFlashMessage({
                message: t('profile.messages.connection error'),
                variant: MessageLevel.Error,
            });
        }
    };

    const handleError = useCallback((error: any) => {
        setLoading(false);
        console.error(error);
        throw error;
    }, []);

    const handleInitializationError = useCallback(() => {
        if (hasUser.current) {
            return;
        }
        console.error('Error initializing application');
        setLoading(false);
        setError(true);
    }, []);

    const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>): React.ChangeEventHandler<HTMLInputElement> =>
            e => setter(e.currentTarget.value);

    const initializeApp = useCallback(() => {
        const token = getAuthenticationToken();
        if (token) {
            SessionStore.delete(awaitAuthStorageKey);
            getProfile()
                .then(user => ({ ...user, sub: getAuthenticatedUserId() }))
                .then(user => {
                    hasUser.current = true;
                    updateFormFromApiData({ ...user, user_metadata: user.user_metadata || {} });
                })
                .catch(handleError);
            setLoading(false);
        } else {
            setError(true);
        }
    }, [ handleError ]);

    return (
        <>
            <InitializationHelper
                onSuccess={initializeApp}
                onError={handleInitializationError}
            />
            {!loading && error && (
                <Box marginTop={1}>
                    <FlashMessage
                        variant={MessageLevel.Error}
                        message={t('profile.messages.not initialized')}
                    />
                </Box>
            )}
            {loading && (
                <Box textAlign="center" marginTop={32}>
                    <CircularProgress color="secondary"/>
                </Box>
            )}
            {!!auth0User && (
                <CardWithClearing>
                    <CardTitle title={t('profile.title') + ''} icon={<UserIcon />} />
                    <CardContent>
                        {!auth0User.email_verified && (
                            <Box marginBottom={2} borderRadius={4} bgcolor="#eee" padding={1}>
                                <Typography variant="body2">
                                    {t('profile.notVerified.text') + ''}
                                </Typography>
                                {false && (
                                    <Box marginTop={1}>
                                        <Button variant="outlined" size="small" onClick={resendVerificationMail}>
                                            {t('profile.notVerified.resend') + ''}
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        )}
                        <form onSubmit={handleSubmit}>
                            <Box marginBottom={3}>
                                <TextField
                                    fullWidth
                                    label={t('profile.firstName.label') + ''}
                                    value={firstName}
                                    onChange={handleChange(setFirstName)}
                                    disabled={!auth0User.email_verified}
                                />
                            </Box>
                            <Box marginBottom={3}>
                                <TextField
                                    fullWidth
                                    label={t('profile.lastName.label') + ''}
                                    value={lastName}
                                    onChange={handleChange(setLastName)}
                                    disabled={!auth0User.email_verified}
                                />
                            </Box>
                            <Box marginBottom={3}>
                                <TextField
                                    fullWidth
                                    label={t('profile.email.label') + ''}
                                    value={email}
                                    type="email"
                                    required
                                    onChange={handleChange(setEmail)}
                                    disabled={!auth0User.email_verified}
                                />
                            </Box>
                            <Box marginBottom={3}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="user-language-label">{t('profile.language.label') + ''}</InputLabel>
                                    <Select native id="user-language-label" value={language.slice(0, 2)} onChange={handleChange(setLanguage)}>
                                        {Object.entries(languageOptions).map(([ language, label ]) => (
                                            <option key={language} value={language}>{label}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                                {t('profile.submit') + ''}
                            </Button>
                        </form>
                    </CardContent>
                </CardWithClearing>
            )}
        </>);
};
