import queryString from 'query-string';

export const featureParameter = 'flag';

export enum Feature {
    Login = 1,
    Signup = 2,
    PasswordReset = 4,
}

let disabledFeatures: number|null = null;
export const getDisabledFeatures = () => {
    if (disabledFeatures === null) {
        const q = queryString.parse(document.location.search, { parseNumbers: true });
        if (featureParameter in q && typeof q[featureParameter] === 'number') {
            disabledFeatures = q[featureParameter];
        } else {
            disabledFeatures = 0;
        }
    }
    return disabledFeatures;
};

export const featureEnabled = (feature: Feature) => (getDisabledFeatures() & feature) === 0;
export const featureDisabled = (feature: Feature) => !featureEnabled(feature);
