module.exports = {
    lock: {
        passwordReset: {
            configurationError: 'Es ist ein Fehler aufgetreten. Das Formular scheint falsch konfiguriert zu sein.',
            headerText: 'Passwort angeben für<br />{email}',
            networkError: 'Der Server ist nicht erreichbar, es besteht ein Netzwerkproblem.',
            passwordConfirmationMatchError: 'Das Passwort und die Bestätigung davon müssen gleich sein.',
            passwordConfirmationPlaceholder: 'Neues Passwort bestätigen',
            passwordHistoryError: 'Passwort wurde bereits verwendet',
            passwordPlaceholder: 'Neues Passwort',
            passwordStrength: {
                containsAtLeast: 'Das Passwort muss mindestens %d der folgenden %d Arten von Zeichen enthalten:',
                identicalChars: 'Nicht mehr als %d gleiche Zeichen in einer Zeile (z.B., "%s" ist nicht erlaubt)',
                lengthAtLeast: 'Mindestens %d Zeichen lang',
                lowerCase: 'Kleinbuchstaben (a-z)',
                nonEmpty: 'Passwort darf nicht leer sein',
                numbers: 'Zahlen (z.B. 0-9)',
                shouldContain: 'Sollte enthalten:',
                specialCharacters: 'Sonderzeichen (z.B. !@#$%^&*)',
                upperCase: 'Großbuchstaben (A-Z)',
            },
            serverError: 'Es gab einen Fehler bei der Verarbeitung Ihrer Anfrage.',
            submit: 'Passwort ändern',
            successMessage: 'Ihr Passwort wurde erfolgreich geändert.',
            timeoutError: 'Der Server ist nicht erreichbar, bitte versuchen Sie es erneut.',
            title: 'Passwort ändern',
            weakPasswordError: 'Passwort ist zu schwach',
        },
    },
    translation: {
        general: {
            loading: 'Lädt',
        },
        join: {
            action: 'Zugang anlegen',
            firstName: {
                label: 'Vorname (optional)',
            },
            header: {
                subtitle: 'Erstellen Sie ein axes4 Konto, um die Einladung abzuschließen.',
                title: 'axes4 ID erstellen',
            },
            lastName: {
                label: 'Nachname (optional)',
            },
            messages: {
                3040: 'Dies ist kein gültiger Einladungslink.',
                3041: 'Dies ist kein gültiger Einladungslink.',
                3042: 'Diese Einladung existiert nicht.',
                4001: 'Passwort ist zu schwach.',
                error: 'axes4 Konto konnte nicht erstellt werden',
                noCode: 'Dies ist kein gültiger Einladungslink.',
                serverConnection: 'Konnte keine Verbindung zum Server herstellen.',
            },
            password: {
                label: 'Passwort',
            },
            text: 'Erstellen Sie ein axes4 Konto für Ihre E-Mail-Adresse <strong>{{email}}</strong>, um dem Lizenzkonto <strong>{{accountName}}</strong> beizutreten.',
        },
        links: {
            login: 'Zum axes4 Login',
            passwordReset: 'Passwort vergessen?',
            signup: 'Zugang anlegen',
        },
        loggedout: {
            header: {
                subtitle: 'Ihre Sitzung ist abgelaufen.',
                title: 'Abgemeldet',
            },
            toProfile: 'Erneut anmelden',
        },
        login: {
            action: 'Anmelden',
            email: {
                label: 'E-Mail',
            },
            header: {
                subtitle: 'Für Anwendungen von axes4 anmelden',
                title: 'axes4 Login',
            },
            messages: {
                access_denied: 'Falsche E-Mail oder falsches Passwort',
                request_error: 'Anmeldung konnte nicht übermittelt werden',
            },
            password: {
                label: 'Passwort',
            },
            username: {
                label: 'Benutzername',
            },
        },
        messages: {
            error: 'Es ist ein Fehler aufgetreten',
            passwordPolicy: {
                containsAtLeast: 'Das Passwort muss mindestens %d der folgenden %d Arten von Zeichen enthalten:',
                fulfilled: 'erfüllt:',
                identicalChars: 'Nicht mehr als %d gleiche Zeichen in einer Zeile (z.B., "%s" ist nicht erlaubt)',
                lengthAtLeast: 'Mindestens %d Zeichen lang',
                lowerCase: 'Kleinbuchstaben (a-z)',
                nonEmpty: 'Passwort darf nicht leer sein',
                numbers: 'Zahlen (z.B. 0-9)',
                shouldContain: 'Sollte enthalten:',
                specialCharacters: 'Sonderzeichen (z.B. !@#$%^&*)',
                unfulfilled: 'nicht erfüllt:',
                upperCase: 'Großbuchstaben (A-Z)',
            },
        },
        popup: {
            subtitle: 'Für Anwendungen von axes4 anmelden',
            title: 'axes4 Login',
        },
        profile: {
            email: {
                label: 'E-Mail-Adresse',
            },
            firstName: {
                label: 'Vorname',
            },
            introText: 'Im axes4 Profil haben Sie die Möglichkeit, die Angaben zu Ihrer Person und anwendungsübergreifende Einstellungen zu bearbeiten.',
            language: {
                label: 'Sprache',
            },
            lastName: {
                label: 'Nachname',
            },
            logout: 'Abmelden',
            messages: {
                'account saved': 'Die Änderungen wurden gespeichert.',
                'connection error': 'Die Daten konnten nicht gespeichert werden. Versuchen Sie, die Seite neu zu laden.',
                'email changed': 'Die E-Mail-Adresse wurde geändert. Um diese neue Adresse zum Anmelden zu verwenden, muss sie erst bestätigt werden.',
                'not initialized': 'Die Anwendung konnte nicht initialisiert werden. Versuchen Sie, die Seite neu zu laden.',
            },
            notVerified: {
                resend: 'Bestätigung erneut versenden',
                text: 'Ihre E-Mail-Adresse ist noch nicht verifiziert. Daher können Sie noch keine Änderungen im axes4 Profil vornehmen.',
            },
            pageTitle: 'axes4 ID',
            submit: 'Speichern',
            title: 'axes4 Konto Einstellungen',
        },
        requestPasswordReset: {
            action: 'Passwort zurücksetzen',
            email: {
                label: 'E-Mail',
            },
            header: {
                subtitle: 'Geben Sie eine E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passworts zu erhalten.',
                title: 'Passwort zurücksetzen',
            },
            messages: {
                'email or username are required': 'Die E-Mail-Adresse ist erforderlich!',
                success: 'Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts geschickt.',
            },
        },
        signup: {
            action: 'axes4 Konto erstellen',
            email: {
                label: 'E-Mail',
            },
            header: {
                subtitle: 'Eine axes4 ID erstellen',
                title: 'Registrieren',
            },
            messages: {
                invalid_password: 'Ungültiges Passwort',
                request_error: 'Es gab ein Problem beim Anlegen Ihres Zugangs.',
                success: 'Ihr axes4 Konto wurde erstellt. Bitte bestätigen Sie ihre E-Mail-Adresse und melden sich an.',
                user_exists: 'Diese E-Mail-Adresse wird bereits verwendet.',
            },
            password: {
                label: 'Passwort',
            },
            username: {
                label: 'Benutzername',
            },
        },
    },
};
