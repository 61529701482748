import React, { Suspense } from 'react';
import { Layout, PageHeader } from '@axes4/react-common/src/components/Layout';
import { FlashMessageContainer } from '@axes4/react-common/src/components/FlashMessages';
import { ProfileProperties } from './ProfileProperties';
import logoSrc from '../../static/img/logo_axes4_64.png';
import { Card, CardContent, Button, Box, makeStyles, Typography } from '@material-ui/core';
import { logout } from './auth0-helper';
import { useTranslation } from 'react-i18next';
import { isAuthenticated } from '@axes4/react-common/src/auth/auth0-helper';
import { resetLanguage } from '@axes4/react-common/src/util/i18n';
/**
 * margin-right: 16px;
transform: translateY(8px);
width 48
 */

const useStyles = makeStyles(theme => ({
    logo: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: theme.spacing(2),
        transform: `translateY(${theme.spacing(1)}px)`,
    },
}));

export const Profile = () => {
    const classes = useStyles({});
    const { t, i18n } = useTranslation();
    const logoutHandler = () => {
        logout();
        resetLanguage(i18n);
    };
    return (
        <Layout
            header={<PageHeader title={(
                <>
                    <img src={logoSrc} alt="axes4 logo" className={classes.logo} />
                    {t('profile.pageTitle')}
                </>
            )} />}
            noDrawer
        >
            <FlashMessageContainer/>
            <Card>
                <CardContent>
                    <Typography variant="body1" paragraph>
                        {t('profile.introText')}
                    </Typography>
                    {isAuthenticated() && (
                        <Box textAlign="right">
                            <Button variant="outlined" onClick={logoutHandler}>{t('profile.logout')}</Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Suspense fallback="An error occurred">
                <ProfileProperties/>
            </Suspense>
        </Layout>
    );
};
