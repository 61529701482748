import auth0 from 'auth0-js';
import QueryString from 'query-string';
import { omit } from '@axes4/react-common/src/util/objects';
import { AuthConfig, getConfig } from './config';
const auth0BaseConfig = getConfig().auth;

export const webAuth = new auth0.WebAuth({
    domain: auth0BaseConfig.customDomain,
    clientID: auth0BaseConfig.clientId,
});

type Auth0Config = AuthConfig & {
    audience: string;
    scope: string;
    responseType: string;
    clientId: string;
    state?: string;
    nonce?: string;
    redirectUri?: string;
    codeChallengeMethod?: string;
    codeChallenge?: string;
    connection: string;
};

export const getAuth0Conf = (): Auth0Config => {
    const {
        audience,
        scope = 'openid profile email',
        response_type: responseType = 'code',
        client_id: clientId,
        state,
        redirect_uri: redirectUri,
        code_challenge_method: codeChallengeMethod = 'S256',
        code_challenge: codeChallenge,
        connection,
        domain,
        nonce,
    } = QueryString.parse(location.search);

    const valuesFromQueryString = {
        audience,
        scope,
        responseType,
        clientId,
        state,
        redirectUri,
        codeChallengeMethod,
        codeChallenge,
        connection,
        domain,
        nonce,
    };

    return Object.assign(
        {},
        omit(auth0BaseConfig, [ 'customDomain' ]),
        Object
            .keys(valuesFromQueryString)
            .reduce((c, k) => valuesFromQueryString[k] ? (c[k] = valuesFromQueryString[k], c) : c, {})
    ) as Auth0Config;
};
