import { webAuth } from '../../auth0';
import { LoginRoutePath } from '../LoginWindow/Login';
import { logout as helperLogout, getLoginUri as helperLoginUri } from '@axes4/react-common/src/auth/auth0-helper';
import { getConfig } from '../../config';
const auth0Credentials = getConfig().auth;

export const auth0Scope = 'openid';

export const getLoginUri = (domain?: string) => {
    const loginArguments: Record<string, string> = {
        client_id: auth0Credentials.clientId,
        connection: auth0Credentials.connection,
        scope: auth0Scope,
    };
    if (domain) {
        loginArguments.domain = domain;
    }
    return LoginRoutePath + helperLoginUri(loginArguments).slice(1);
};

export const logout = () =>
    helperLogout(webAuth)(`${document.location.protocol}//${document.location.host}/`);
