import React, { useEffect, useState } from 'react';
import { Content } from '../common/Content';
import { Input } from '../common/Input';
import { getAuth0Conf, webAuth } from '../../auth0';
import LockIcon from '@axes4/react-icons/jsx/Lock';
import MailIcon from '@axes4/react-icons/jsx/Mail';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '../common/LinkButton';
import { makeStyles } from '@material-ui/core/styles';
import { SubmitButton } from './SubmitButton';
import { RequestPasswordResetRoutePath } from './RequestPasswordReset';
import { SignupRoutePath } from './Signup';
import { usePageTitle } from '../../hooks/layout';
import { Layout } from './Layout';
import { Feature, featureEnabled } from '../../util/feature';
import { Auth0Error, CrossOriginLoginOptions } from 'auth0-js';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/components/FlashMessages';
import { FlashMessageContainer } from '../common/FlashMessageContainer';

export const LoginRoutePath = '/login';

const useStyles = makeStyles({
    center: {
        textAlign: 'center',
    },
});

interface LoginProps {
    onSuccess?(response: unknown): void,
    onError?(err: Auth0Error): void,
}

export const Login: React.FunctionComponent<LoginProps> = props => {
    const { onSuccess, onError } = props;
    const { addMessage, clear } = useFlashMessages();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ busy, setBusy ] = useState(false);
    const { t, i18n } = useTranslation();
    usePageTitle(t('login.action'));
    const auth0Config = getAuth0Conf();

    useEffect(() => {
        clear();
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setBusy(true);
        const loginOptions: CrossOriginLoginOptions = {
            clientID: auth0Config.clientId,
            domain: auth0Config.customDomain,
            responseType: auth0Config.responseType,
            realm: auth0Config.realm,
            audience: auth0Config.audience,
            username: email,
            password,
            redirectUri: auth0Config.redirectUri || document.location.toString(),
            scope: auth0Config.scope,
        };
        if (auth0Config.nonce) {
            loginOptions.nonce = auth0Config.nonce;
        }
        if (auth0Config.state) {
            loginOptions.state = auth0Config.state;
        }
        webAuth.login(loginOptions, (err, resp) => {
            setBusy(false);
            if (err) {
                console.error(err);
                const messageKey = `login.messages.${err.code}`;
                const message = i18n.exists(messageKey) ? t(messageKey) : (err.description || t('messages.error'));
                addMessage({ message, variant: MessageLevel.Error });
                onError?.(err);
            } else {
                addMessage({ message: resp, variant: MessageLevel.Success });
                onSuccess?.(resp);
            }
        });
    };
    const classes = useStyles({});

    return (
        <Layout
            title={t('login.header.title')}
            subtitle={t('login.header.subtitle')}
        >
            <form onSubmit={handleSubmit}>
                <Content>
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.currentTarget.value)}
                        label={t('login.email.label')}
                        autoComplete="email"
                        icon={MailIcon}
                        required
                    />
                    <Input
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.currentTarget.value)}
                        label={t('login.password.label')}
                        autoComplete="password"
                        type="password"
                        icon={LockIcon}
                        required
                    />
                    <FlashMessageContainer/>
                </Content>
                <Content className={classes.center}>
                    {featureEnabled(Feature.PasswordReset) && (
                        <LinkButton
                            to={RequestPasswordResetRoutePath}
                        >
                            {t('links.passwordReset') as string}
                        </LinkButton>
                    )}
                    {featureEnabled(Feature.Signup) && (
                        <LinkButton
                            to={SignupRoutePath}
                        >
                            {t('links.signup') as string}
                        </LinkButton>
                    )}
                </Content>
                <SubmitButton busy={busy}>{t('login.action') as string}</SubmitButton>
            </form>
        </Layout>
    );
};
