import { useEffect } from 'react';

export const usePageTitle = (pageTitle: string) => {
    useEffect(() => {
        const oldTitle = document.title;
        document.title = `${pageTitle} – axes4 ID`;
        return () => {
            document.title = oldTitle;
        };
    }, []);
};
