import React from 'react';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Logo from '../../static/img/logo_axes4_64.png';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 350,
        margin: 'auto',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '90%',
        width: '95%',
    },
    header: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    },
    center: {
        textAlign: 'center',
    },
    logo: {
        height: 64,
    },
}));

interface LayoutProps {
    title?: string;
    subtitle?: string;
    children: React.ReactNode;
}

export const Layout: React.FunctionComponent<LayoutProps> = props => {
    const classes = useStyles({});
    const { t } = useTranslation();
    const title = props.title || t('popup.title');
    const subtitle = props.subtitle || t('popup.subtitle');

    return (
        <Paper className={classes.root}>
            <header className={clsx(classes.center, classes.header)}>
                <img src={Logo} alt="Axes4 Logo" className={classes.logo} />
                <Typography component="h1" variant="h5">{title}</Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
            </header>
            <main>
                {props.children}
            </main>
        </Paper>
    );
};
