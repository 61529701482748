import {
    extendSession,
    getAuthenticationToken,
    isAuthenticated,
    logout,
    useAuth0Init,
} from '@axes4/react-common/src/auth/auth0-helper';
import { SessionStore } from '@axes4/react-common/src/auth/session-store';
import { getLocation } from '@axes4/react-common/src/util';
import { useCallback, useEffect } from 'react';
import { auth0Scope, getLoginUri } from './auth0-helper';
import { getConfig } from '../../config';

const auth0Credentials = getConfig().auth;

const auth0WebAuthConfig = {
    clientID: auth0Credentials.clientId,
    domain: auth0Credentials.customDomain,
    audience: auth0Credentials.audience,
    redirectUri: getLocation(),
    responseType: 'token id_token',
    scope: auth0Scope,
};

const awaitAuthStorageKey = 'await_auth';

type InitializationHelperProps = {
    onSuccess: () => void;
    onError: () => void;
}

export const InitializationHelper = (props: InitializationHelperProps) => {
    const { loading, webAuth } = useAuth0Init(auth0WebAuthConfig, props.onError);

    const initializeApp = useCallback(() => {
        const token = getAuthenticationToken();
        if (token) {
            props.onSuccess();
        }
    }, [ props.onSuccess ]);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!SessionStore.has(awaitAuthStorageKey)) {
            window.setTimeout(() => {
                SessionStore.put(awaitAuthStorageKey, 'true');
                extendSession(webAuth, () => {
                    if (isAuthenticated()) {
                        logout(null)(null);
                        document.location.href = '/loggedout';
                    }
                }, true, false)()
                    .then(initializeApp)
                    .catch(() => {
                        props.onError();
                        document.location.href = getLoginUri();
                    });
            }, 150);
        } else {
            // clear awaitAuth flag after 10s to enable reload
            window.setTimeout(() => {
                SessionStore.delete(awaitAuthStorageKey);
            }, 10000);
            window.setTimeout(() => {
                initializeApp();
            }, 200);
        }
    }, [ loading, initializeApp, props.onError ]);

    return null;
};
