import React from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import theme from '@axes4/react-common/src/ui/theme';
import '@axes4/react-common/src/ui/roboto.css';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isAuthenticated } from '@axes4/react-common/src/auth/auth0-helper';
import { Layout } from '../LoginWindow/Layout';
import { LinkButton } from '../common/LinkButton';
import { Content } from '../common/Content';
import { FlashMessageProvider } from '@axes4/react-common/src/hooks/flashmessage';
const Profile = React.lazy(() => import('../Profile'));
const LoginWindow = React.lazy(() => import('../LoginWindow'));

const useStyles = makeStyles(theme => ({
    '@global': {
        'html, body, #app': {
            height: '100%',
        },
    },
    root: {
        background: theme.palette.background.default,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
}));

const LoadingState = () => {
    const { t } = useTranslation();
    const classes = useStyles({});
    return (
        <div className={classes.root}>
            <span style={{ margin:'auto' }}>
                {t('general.loading') as string}
            </span>
        </div>
    );
};

const hasRedirectParameter = () => document.location.search.indexOf('redirect_uri') >= 0;

const AppContent = () => {
    const classes = useStyles({});
    const { t } = useTranslation();

    if (!hasRedirectParameter() && document.location.pathname === '/') {
        document.location.pathname = '/profile';
        return '';
    }

    return (
        <Switch>
            <Route path="/profile">
                <Profile/>
            </Route>
            {!isAuthenticated() && (
                <Route path="/loggedout">
                    <div className={classes.root}>
                        <Layout
                            title={t('loggedout.header.title')}
                            subtitle={t('loggedout.header.subtitle')}
                        >
                            <Content center>
                                <LinkButton to="/profile">{t('loggedout.toProfile') as string}</LinkButton>
                            </Content>
                        </Layout>
                    </div>
                </Route>
            )}
            <Route>
                <div className={classes.root}>
                    <LoginWindow/>
                </div>
            </Route>
        </Switch>
    );
};

export const App = () => {
    return (
        <>
            <MuiThemeProvider theme={theme}>
                <Router>
                    <CssBaseline/>
                    <FlashMessageProvider>
                        <React.Suspense fallback={<LoadingState/>}>
                            <AppContent/>
                        </React.Suspense>
                    </FlashMessageProvider>
                </Router>
            </MuiThemeProvider>
        </>
    );
};
